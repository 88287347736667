<template>
    <div class="template-builder__input-column">
        <template-builder-input-group
            @input="handleInput($event, index)"
            v-for="(item, index) in modelValue"
            :custom-items-for-select="selectItems"
            :default-props="defaultProps"
            :key="`${JSON.stringify(item)}-${index}`"
            :has-separator="false"
            :value="item"
        ></template-builder-input-group>
    </div>
</template>

<script>
import { isEqual, cloneDeep } from 'lodash';
export default {
    name: 'template-builder-input-column',
    components: {
        TemplateBuilderInputGroup: () =>
            import('@/components/builder/optionList/template-builder/template-builder-input-group'),
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        defaultProps: {
            type: Object,
            required: true,
        },
    },
    computed: {
        selectItems() {
            return this.defaultProps.getItemsForColumnSelect();
        },
    },
    created() {
        this.modelValue = cloneDeep(this.value);
    },
    data() {
        return {
            modelValue: null,
        };
    },
    methods: {
        handleInput(val, index) {
            this.modelValue[index] = cloneDeep(val);
            this.$emit('input', this.modelValue);
        },
    },
    watch: {
        value: {
            handler(newVal, oldVal) {
                if (!isEqual(newVal, oldVal)) {
                    this.modelValue = cloneDeep(newVal);
                }
            },
            deep: true,
        },
    },
};
</script>

<style></style>
